import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { getSNOMEDConcepts } from './api';
import './App.css';

interface Patient {
  id: string;
  name: string;
}

interface FormData {
  statinTreatment: string;
  bloodPressureMedication: string;
  AspirinMedication: string;
  ADPMedication: string;
  DipyridamoleMedication: string;
  WarfarinMedication: string;
  AnticoagulantMedication: string;
  stroke: string;
  tia: string;
  myocardialInfarction: string;
  atrialFibrillation: string;
  diabetes: string;
  strokeType?: string;
  BTsysObservation: string;
  BTdiaObservation: string;
  LDLObservation: string;
}

const App: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    statinTreatment: '',
    bloodPressureMedication: '',
    AspirinMedication: '',
    ADPMedication: '',
    DipyridamoleMedication: '',
    WarfarinMedication: '',
    AnticoagulantMedication: '',
    stroke: '',
    tia: '',
    myocardialInfarction: '',
    atrialFibrillation: '',
    diabetes: '',
    strokeType: '',
    BTsysObservation: '',
    BTdiaObservation: '',
    LDLObservation: '',
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Patient[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');
  const [lastStrokeDate, setLastStrokeDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false); // Ny state for loading
  const [selectedMetadata, setSelectedMetadata] = useState<string | null>(null); // For å håndtere visning av metadata
  const [showMetadata, setShowMetadata] = useState<boolean>(false); // State for å vise metadata


  const handleMetadataClick = (metadata: string) => {
    setSelectedMetadata(metadata);
    setShowMetadata(true); // Viser metadata-modalen
  };

  const handleCloseMetadata = () => {
    setShowMetadata(false); // Lukker metadata-modalen
  };



  // Ny funksjon for å håndtere skjemaendringer
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
    }));
  };

  // Funksjon for å sjekke MedicationRequest
  const hasMedicationRequest = (concepts: string[], resources: any[]) => {
    return resources.some((entry: any) =>
      entry.resource.resourceType === 'MedicationRequest' &&
      entry.resource.medicationCodeableConcept &&
      entry.resource.medicationCodeableConcept.coding.some((coding: any) =>
        concepts.includes(coding.code)
      )
    );
  };

  const fetchData = useCallback(async (id: string) => {
    try {
      setLoading(true); // Start loader
      const statinTreatmentConcepts: string[] = await getSNOMEDConcepts(`
        315053001 |Administration of prophylactic statin (procedure)| OR
        134350008 |Lipid-lowering therapy (procedure)| OR
        (
            < 373873005 |Pharmaceutical / biologic product| : 
            << 127489000 |Has active ingredient| = ( 
                << 372912004 |Substance with 3-hydroxy-3-methylglutaryl-coenzyme A reductase inhibitor mechanism of action (substance)| OR
                << 409149001 |Ezetimibe (substance)| OR
                << 416897008 |Bile acid sequestrant (substance)| OR
                << 737573001 |Substance with proprotein convertase subtilisin/kexin type 9 inhibitor mechanism of action (substance)|
            )
        )
      `);

      const bloodPressureMedicationConcepts: string[] = await getSNOMEDConcepts(`
        < 373873005 |Pharmaceutical / biologic product| : 
        << 127489000 |Has active ingredient| = << 372586001 |Hypotensive agent (substance)|
      `);

      const AspirinMedicationConcepts: string[] = await getSNOMEDConcepts(`
      (< 373873005 |Pharmaceutical / biologic product| : 
      << 127489000 |Has active ingredient| = 
      << 387458008 |Aspirin (substance)|)
      OR 
      (<<7947003 |Product containing aspirin (medicinal product)|)
      `);

      const ADPMedicationConcepts: string[] = await getSNOMEDConcepts(`
      < 373873005 |Pharmaceutical / biologic product| :  << 127489000 |Has active ingredient| = (
        << 386952008 |Clopidogrel (substance)| OR
        << 443129001 |Prasugrel (substance)| OR
        << 698805004 |Ticagrelor (substance)| OR
        << 716118009 |Cangrelor (substance)|
      )
      `);
      const DipyridamoleMedicationConcepts: string[] = await getSNOMEDConcepts(`
      < 373873005 |Pharmaceutical / biologic product| :  << 127489000 |Has active ingredient| = << 387371005 |Dipyridamole (substance)|
      `);

      const WarfarinMedicationConcepts: string[] = await getSNOMEDConcepts(` 
      < 373873005 |Pharmaceutical / biologic product| :  << 127489000 |Has active ingredient| = << 372756006 |Warfarin (substance)|
      `);

      const AnticoagulantMedicationConcepts: string[] = await getSNOMEDConcepts(`
      < 373873005 |Pharmaceutical / biologic product| : 
      (<< 127489000 |Has active ingredient| = (
          (<< 372862008 |Anticoagulant (substance)|) 
          MINUS (<< 372756006 |Warfarin (substance)|)))
      AND
      (<< 411116001 |Has manufactured dose form (attribute)| = << 385268001 |Oral dose form (dose form)|)
      `);
     
      const infarktConcepts: string[] = await getSNOMEDConcepts('<<230690007 |Cerebrovascular accident (disorder)|');
      const blødningConcepts: string[] = await getSNOMEDConcepts('<<274100004 |Cerebral hemorrhage (disorder)|');
      const tiaConcepts: string[] = await getSNOMEDConcepts('<<266257000 |Transient ischemic attack (disorder)|');
      const miConcepts: string[] = await getSNOMEDConcepts('<<22298006 |Myocardial infarction (disorder)|');
      const afConcepts: string[] = await getSNOMEDConcepts('<<49436004 |Atrial fibrillation (disorder)|');
      const diabetesConcepts: string[] = await getSNOMEDConcepts('<<73211009 |Diabetes mellitus (disorder)|');
      const BTsysObservationConcepts: string[] = await getSNOMEDConcepts('<<271649006 |Systolic blood pressure (observable entity)|');
      const BTdiaObservationConcepts: string[] = await getSNOMEDConcepts('<<271650006 |Diastolic blood pressure (observable entity)|');
      const LDLObservationConcepts: string[] = await getSNOMEDConcepts('<<147901000202100 |Concentration of low density lipoprotein cholesterol (observable entity)|');

      const response = await axios.get(`https://fhir.conteir.no/fhir/Patient/${id}/$everything?_count=2000`);
      const resources = response.data.entry || [];

      const lastStrokeDateObj = lastStrokeDate ? new Date(lastStrokeDate) : new Date();

    

      const hasCondition = (concepts: string[], filterByDate: boolean = true) =>
        resources.some((entry: any) => {
          const conditionDate = new Date(entry.resource.onsetDateTime);
          return (
            entry.resource.resourceType === 'Condition' &&
            entry.resource.code &&
            entry.resource.code.coding &&
            entry.resource.code.coding.some((coding: any) => concepts.includes(coding.code)) &&
            (!filterByDate || conditionDate < lastStrokeDateObj)
          );
        });

      const determineStrokeType = () => {
        const hasInfarkt = hasCondition(infarktConcepts);
        const hasBlødning = hasCondition(blødningConcepts);

        if (hasInfarkt && hasBlødning) {
          return '4'; // Både infarkt og blødning
        } else if (hasInfarkt) {
          return '1'; // Infarkt
        } else if (hasBlødning) {
          return '2'; // Blødning
        } else {
          return '9'; // Ukjent
        }
      };


      const findLatestObservationValue = (concepts: string[], resources: any[]) => {
        const filteredObservations = resources
          .filter((entry: any) => 
            entry.resource.resourceType === 'Observation' &&
            entry.resource.code &&
            entry.resource.code.coding &&
            entry.resource.code.coding.some((coding: any) => concepts.includes(coding.code)) &&
            entry.resource.effectiveDateTime && 
            (!lastStrokeDateObj || new Date(entry.resource.effectiveDateTime) > lastStrokeDateObj)
          )
          .sort((a: any, b: any) => 
            new Date(b.resource.effectiveDateTime).getTime() - new Date(a.resource.effectiveDateTime).getTime()
          );
  
        if (filteredObservations.length > 0) {
          return filteredObservations[0].resource.valueQuantity?.value; // Return the latest numerical value
        }
        return null;
      };
  
      // Use the new findLatestObservationValue function
      const BTsysValue = findLatestObservationValue(BTsysObservationConcepts, resources);
      const BTdiaValue = findLatestObservationValue(BTdiaObservationConcepts, resources);
      const LDLValue = findLatestObservationValue(LDLObservationConcepts, resources);




      setFormData({
        statinTreatment: hasMedicationRequest(statinTreatmentConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        bloodPressureMedication: hasMedicationRequest(bloodPressureMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        AspirinMedication: hasMedicationRequest(AspirinMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        ADPMedication: hasMedicationRequest(ADPMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        DipyridamoleMedication: hasMedicationRequest(DipyridamoleMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        WarfarinMedication: hasMedicationRequest(WarfarinMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        AnticoagulantMedication: hasMedicationRequest(AnticoagulantMedicationConcepts, resources) ? '1' : '2', // 1: Ja, 2: Nei
        stroke: hasCondition(infarktConcepts.concat(blødningConcepts)) ? '1' : '2', // 1: Ja, 2: Nei
        tia: hasCondition(tiaConcepts) ? '1' : '2', // 1: Ja, 2: Nei
        myocardialInfarction: hasCondition(miConcepts) ? '1' : '2', // 1: Ja, 2: Nei
        atrialFibrillation: hasCondition(afConcepts, false) ? '1' : '2', // 1: Ja, 2: Nei
        diabetes: hasCondition(diabetesConcepts, false) ? '1' : '2', // 1: Ja, 2: Nei
        strokeType: determineStrokeType(), // Bestem type hjerneslag basert på logikken
        BTsysObservation: BTsysValue !== null ? BTsysValue.toString() : 'ikkeUtført',
        BTdiaObservation: BTdiaValue !== null ? BTdiaValue.toString() : 'ikkeUtført',
        LDLObservation: LDLValue !== null ? LDLValue.toString() : 'ikkeUtført',
      });
    } catch (error) {
      console.error('Error fetching patient data:', error);
    } finally {
      setLoading(false); // Stop loader
    }
  }, [lastStrokeDate]);

  // Funksjon for å håndtere knappetrykk
  const handleSearch = () => {
    if (selectedPatientId) {
      fetchData(selectedPatientId);
    }
  };

  // Funksjon for å håndtere inputendring
  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setInputValue(query);

    if (query.length > 2) {  // Start søket når det er mer enn 2 tegn
      try {
        const response = await axios.get(`https://fhir.conteir.no/fhir/Patient?name=${query}`);
        const entries = response.data.entry || [];

        const patients = entries.map((entry: any) => ({
          id: entry.resource.id,
          name: entry.resource.name[0].text || `${entry.resource.name[0].given.join(' ')} ${entry.resource.name[0].family}`
        }));

        setSuggestions(patients); // Oppdater forslag
      } catch (error) {
        console.error('Error fetching patient suggestions:', error);
      }
    } else {
      setSuggestions([]); // Fjern forslag hvis søket er for kort
    }
  };

  // Funksjon for å håndtere valg av pasient fra listen
  const handleSuggestionClick = (patient: Patient) => {
    setInputValue(patient.name);
    setSelectedPatientId(patient.id);
    setSuggestions([]); // Tøm forslagslisten etter valg
  };

  return (
    <div className="container">
      <h1>Norsk hjerneslagregister</h1>
      <div className="form-group">
        <label>Søk etter pasient</label>
        <input
          type="text"
          placeholder="Søk etter pasient"
          value={inputValue}
          onChange={handleInputChange}  // Bruk handleInputChange her
        />
        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((patient) => (
              <li key={patient.id} onClick={() => handleSuggestionClick(patient)}>
                {patient.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="form-group">
        <label>Dato for siste hjerneslag</label>
        <input
          type="date"
          placeholder="Dato for siste hjerneslag"
          value={lastStrokeDate}
          onChange={(e) => setLastStrokeDate(e.target.value)}
        />
      </div>
      {/* Loader knapp 
      <button onClick={handleSearch} disabled={loading}>
        {loading ? 'Laster...' : 'Hent fra EPJ'}
      </button>*/}

        {/* Viser enten last-knappen eller tre-prikk loader basert på loading state */}
        {loading ? (
        <div className="loader">
        <span className="loader-dot"></span>
        <span className="loader-dot"></span>
        <span className="loader-dot"></span>
        </div>
      ) : (
        <button onClick={handleSearch}>
          Hent fra EPJ
        </button>
      )}

      <h2>Risikofaktorer før hjerneslaget</h2>

            <form>
                <div className="form-group">
                    <div className="form-label-button-container">
                      <label>Statin og annen lipidsenkende behandling før innleggelsen</label>
                      <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
  << 127489000 |Has active ingredient| = (  
    << 372912004 |Substance with 3-hydroxy-3-methylglutaryl-coenzyme A reductase inhibitor mechanism of action (substance)|
    OR << 409149001 |Ezetimibe (substance)| 
    OR << 416897008 |Bile acid sequestrant (substance)|
    OR << 737573001 |Substance with proprotein convertase subtilisin/kexin type 9 inhibitor mechanism of action (substance)| )`)}></button>
                    </div>

                    <select name="statinTreatment" value={formData.statinTreatment} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                    <div className="undertext">For eksempel: Atorvastatin, Atozet, Cholestagel, Crestor, Ezetrol, Ezetimib, Fluvastatin, Inegy, Leqvio, Lescol, Lipitor, Lojuxta, Lovastatin, Omacor, Praluent, Pravastatin, Questran, Repatha, Rosuvastatin, Simvastatin, Vazkepa, Waylivra, Zenon, Zocor.</div>

                </div>
                <div className="form-group">
                <div className="form-label-button-container">
                    <label>Medikamentell behandling for høyt blodtrykk før innleggelsen</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
  << 127489000 |Has active ingredient| = 
    << 372586001 |Hypotensive agent (substance)|`)}></button>
                    </div>
                    <select name="bloodPressureMedication" value={formData.bloodPressureMedication} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                    <div className="undertext">1: Ja, dersom pasienten før innleggelse får blodtrykkssenkende medisin, også om det er uklart om høyt blodtrykk er indikasjonen for medikamentet pasienten får. Aktuelle medikamenter: Kalsiumblokkere, ACE-hemmere, A2 (angiotensin), betablokkere og diuretica</div>
                </div>

                <div className="form-group">
                  <div className="form-label-button-container">
                    <label>Tidligere hjerneslag</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
<< 230690007 |Cerebrovascular accident (disorder)| 
OR << 274100004 |Cerebral hemorrhage (disorder)|`)}></button>
                    </div>
                    <select name="stroke" value={formData.stroke} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
                            {/* Vise feltet for "Type tidligere hjerneslag" kun når "Tidligere hjerneslag" er "1: Ja" */}
              {formData.stroke === '1' && (
                <div className="form-group">
                  <div className="form-label-button-container">
                    <label>Type tidligere hjerneslag</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
1: Infarkt:  <<230690007 |Cerebrovascular accident (disorder)| 
2: Blødning: <<274100004 |Cerebral hemorrhage (disorder)|

4: Både infarkt og blødning: (1: Infarkt) + (2: Blødning)`)}></button>
                    </div>
                    <select name="strokeType" value={formData.strokeType} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Infarkt</option>
                        <option value="2">2: Blødning</option>
                        <option value="3">3: Uspesifisert</option>
                        <option value="4">4: Både infarkt og blødning</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
              )}
                <div className="form-group">
                <div className="form-label-button-container">
                    <label>Tidligere TIA</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
<<266257000 |Transient ischemic attack (disorder)|`)}></button>
                    </div>

                    <select name="tia" value={formData.tia} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
                <div className="form-group">
                <div className="form-label-button-container">
                    <label>Tidligere hjerteinfarkt</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
<<22298006 |Myocardial infarction (disorder)|`)}></button>
                    </div>

                    <select name="myocardialInfarction" value={formData.myocardialInfarction} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
                <div className="form-group">
                <div className="form-label-button-container">
                    <label>Atrieflimmer</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
<<49436004 |Atrial fibrillation (disorder)|`)}></button>
                    </div>

                    <select name="atrialFibrillation" value={formData.atrialFibrillation} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
                <div className="form-group">
                <div className="form-label-button-container">
                    <label>Diabetes</label>
                    <button type="button" className="knapp" onClick={() => handleMetadataClick(`
<<73211009 |Diabetes mellitus (disorder)|`)}></button>
                    </div>

                    <select name="diabetes" value={formData.diabetes} onChange={handleChange}>
                        <option value="">Velg verdi</option>
                        <option value="1">1: Ja</option>
                        <option value="2">2: Nei</option>
                        <option value="9">9: Ukjent</option>
                    </select>
                </div>
            </form>

<br></br>
<h2>Medikamentell behandling ved utreise</h2>

<form>
    <div className="form-group">
        <div className="form-label-button-container">
          <label>Acetylsalisylsyre (ASA) ved utskrivning</label>
          <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
<< 127489000 |Has active ingredient| = 
<< 387458008 |Aspirin (substance)|`)}></button>
        </div>

        <select name="AspirinMedication" value={formData.AspirinMedication} onChange={handleChange}>
            <option value="">Velg verdi</option>
            <option value="1">1: Ja</option>
            <option value="2">2: Nei</option>
            <option value="9">9: Ukjent</option>
        </select>
        <div className="undertext">For eksempel: Acetylsalisylsyre, Albyl E, Aspirin, Diprasorin</div>

    </div>
    <div className="form-group">
    <div className="form-label-button-container">
        <label>ADP-reseptorblokker ved utskrivning</label>
        <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
<< 127489000 |Has active ingredient| = (
    << 386952008 |Clopidogrel (substance)| OR
    << 443129001 |Prasugrel (substance)| OR
    << 698805004 |Ticagrelor (substance)| OR
    << 716118009 |Cangrelor (substance)|)
`)}></button>
        </div>
        <select name="ADPMedication" value={formData.ADPMedication} onChange={handleChange}>
            <option value="">Velg verdi</option>
            <option value="1">1: Ja</option>
            <option value="2">2: Nei</option>
            <option value="9">9: Ukjent</option>
        </select>
        <div className="undertext">For eksempel: Brilique, Clopidogrel, Efient, Plavix</div>
    </div>

    <div className="form-group">
    <div className="form-label-button-container">
        <label>Dipyridamol ved utskrivning</label>
        <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
<< 127489000 |Has active ingredient| = 
<< 387371005 |Dipyridamole (substance)|`)}></button>
        </div>
        <select name="DipyridamoleMedication" value={formData.DipyridamoleMedication} onChange={handleChange}>
            <option value="">Velg verdi</option>
            <option value="1">1: Ja</option>
            <option value="2">2: Nei</option>
            <option value="9">9: Ukjent</option>
        </select>
        <div className="undertext">For eksempel: Aponova, Diprasorin (Orion)</div>
    </div>

    <div className="form-group">
    <div className="form-label-button-container">
        <label>Warfarin ved utskrivning</label>
        <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
<< 127489000 |Has active ingredient| = 
<< 372756006 |Warfarin (substance)|`)}></button>
        </div>
        <select name="WarfarinMedication" value={formData.WarfarinMedication} onChange={handleChange}>
            <option value="">Velg verdi</option>
            <option value="1">1: Ja</option>
            <option value="2">2: Nei</option>
            <option value="9">9: Ukjent</option>
        </select>
        <div className="undertext">For eksempel: Marevan, Warfarin Orion</div>
    </div>
    <div className="form-group">
    <div className="form-label-button-container">
        <label>Andre perorale antikoagulasjonsmidler enn Warfarin ved utskrivning</label>
        <button type="button" className="knapp" onClick={() => handleMetadataClick(`
< 373873005 |Pharmaceutical / biologic product| : 
  (<< 127489000 |Has active ingredient| = (
      (<< 372862008 |Anticoagulant (substance)|) 
      MINUS (<< 372756006 |Warfarin (substance)|))) 
  AND 
  (<< 411116001 |Has manufactured dose form (attribute)| = 
      << 385268001 |Oral dose form (dose form)|)`)}></button>
        </div>
        <select name="AnticoagulantMedication" value={formData.AnticoagulantMedication} onChange={handleChange}>
            <option value="">Velg verdi</option>
            <option value="1">1: Ja</option>
            <option value="2">2: Nei</option>
            <option value="9">9: Ukjent</option>
        </select>
        <div className="undertext">For eksempel: Eliquis, Lixiana, Pradaxa, Xarelto</div>
    </div>
 </form>

 <br></br>
<h2>Utskriving</h2>

<form>
    <div className="form-group">
          <div className="form-label-button-container">
            <label>Systolisk blodtrykk ved utskriving (0-2 dager før utskriving)</label>
          </div>
          <input
            type="number"
            name="BTsysObservation"
            value={formData.BTsysObservation === 'ikkeUtført' ? '' : formData.BTsysObservation}
            onChange={handleChange}
            placeholder="mmHg"
          />
        <div className="undertext">Gjennomføres om mulig sittende med tre målinger. Resultatet blir gjennomsnittet av de to siste målingene.</div>
        <input
        type="checkbox"
        checked={formData.BTsysObservation === 'ikkeUtført'}
        onChange={() =>
          setFormData({
            ...formData,
            BTsysObservation: formData.BTsysObservation === 'ikkeUtført' ? '' : 'ikkeUtført',
          })
        }
      />
      Ikke utført
    </div>

    <div className="form-group">
          <div className="form-label-button-container">
            <label>Diastolisk blodtrykk ved utskriving (0-2 dager før utskriving)</label>
          </div>
          <input
            type="number"
            name="BTdiaObservation"
            value={formData.BTdiaObservation === 'ikkeUtført' ? '' : formData.BTdiaObservation}
            onChange={handleChange}
            placeholder="mmHg"
          />
        <div className="undertext">Gjennomføres om mulig sittende med tre målinger. Resultatet blir gjennomsnittet av de to siste målingene.</div>
        <input
        type="checkbox"
        checked={formData.BTdiaObservation === 'ikkeUtført'}
        onChange={() =>
          setFormData({
            ...formData,
            BTdiaObservation: formData.BTdiaObservation === 'ikkeUtført' ? '' : 'ikkeUtført',
          })
        }
      />
      Ikke utført
    </div>

    <div className="form-group">
      <div className="form-label-button-container">
        <label>LDL-kolesterol i løpet av akuttoppholdet</label>
      </div>
      <input
        type="number"
        step="0.01"
        name="LDLObservation"
        value={formData.LDLObservation === 'ikkeUtført' ? '' : formData.LDLObservation}
        onChange={handleChange}
        placeholder="mmol/l"
      />
      <div></div>
      <input
        type="checkbox"
        checked={formData.LDLObservation === 'ikkeUtført'}
        onChange={() =>
          setFormData({
            ...formData,
            LDLObservation: formData.LDLObservation === 'ikkeUtført' ? '' : 'ikkeUtført',
          })
        }
      />
      Ikke utført
    </div>
 </form>

              {/* Modal for å vise metadata */}
        {showMetadata && (
          <div className="metadata-modal">
            <div className="metadata-content">
              <h3>ECL-streng</h3>
              <div className="metadata-text">
              <pre>{selectedMetadata}</pre> </div>
              <button onClick={handleCloseMetadata}>Lukk</button>
            </div>
        </div>
      )}
        </div>
    );
};

export default App;
