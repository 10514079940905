import axios from 'axios';

const FHIR_BASE_URL = "https://fhir.conteir.no/fhir";

export interface Condition {
    resource: {
        code: {
            coding: {
                system: string;
                code: string;
                display: string;
            }[];
        };
        onsetDateTime: string;
    };
}

export const getPatientConditions = async (patientId: string): Promise<Condition[]> => {
    try {
        const response = await axios.get<{ entry: Condition[] }>(`${FHIR_BASE_URL}/Condition?patient=${patientId}`);
        return response.data.entry || [];
    } catch (error) {
        console.error("Error fetching conditions:", error);
        return [];
    }
};

export const getSNOMEDConcepts = async (eclQuery: string): Promise<string[]> => {
    try {
        const response = await axios.get(`https://rainbowcolors.conteir.no/snowstorm/snomed-ct/MAIN/SNOMEDCT-NO/concepts`, {
            params: {
                active: true,
                offset: 0,
                limit: 2000,
                groupByConcept: true,
                ecl: eclQuery,
            }
        });
        return response.data.items.map((item: { conceptId: string }) => item.conceptId);
    } catch (error) {
        console.error("Error fetching SNOMED concepts:", error);
        return [];
    }
};
